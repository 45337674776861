<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      users: [],
      Users: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,

      addRoles: [{}],

      user_info: {},
      new_pass: "",

      user_status: 0,
      data: {},
      roles: [],
      companies: [],

      // appprices:[]
    };
  },
  methods: {
    editUser() {
      console.log(this.user_info);
      this.http
        .post(`users/update-pos-manager`, {
          user_id: this.user_info.id,

          name: this.user_info.name,
          tel: this.user_info.tel,
        })
        .then(() => {
          this.get(this.page);
          setTimeout(() => {
            document.querySelector(".btn-close-edit").click();
          }, 200);
        });
    },
    addUsers() {

      this.data.roles = this.addRoles;
      console.log({ data: this.data });
      this.http.post("users/pos-manager", this.data).then(() => {
        this.data = {};

        this.get(this.page);
        setTimeout(() => {
          document.querySelector(".btn-close-add").click();
        }, 200);
      });
    },
    search() {
      this.SearchMode = true;
      console.log(this.searchUsers);
      this.tot_pages = 0;
      this.http
        .post("users/search", {
          limit: this.limit,
          page: this.page,
          search: this.searchUsers,
          col: "name",
          type: 'pos_hub_manager'
        })
        .then((res) => {
          this.users = res.data;
        });
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getUsers() {
      this.http.get("users", null, null, true).then((res) => {
        this.users = res.data;
        console.log({ users: this.users });
      });
    },
    deleteUser(user) {
      console.log(user);
      var data = {
        title: "popups.deleteuser",
        msg: "popups.deletemsg",
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.confirm(data).then((resp) => {
        this.http.delete("users", user.id).then(() => {
          console.log("delete user", user, resp);
          this.get(this.page);
        });
      });
    },
    userInfo(user) {
      this.user_info = JSON.parse(JSON.stringify(user));
      console.log(this.user_info);
    },
    editPass() {
      console.log(this.user_info.id);
      this.http
        .post("users/update-password", {
          user_id: this.user_info.id,
          pass: this.new_pass,
        })
        .then(() => {
          setTimeout(() => {
            document.querySelector(".btn-close-editpass").click();
          }, 200);
        });
      this.new_pass = "";
    },

    get(page) {
      console.log(this.userstable);
      this.http
        .post("users/paginate", {
          limit: this.limit,
          page: page,
          type: "pos_hub_manager"
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.users = res.data;
        });
    },

    getRole() {
      this.http.get("roles", null, null, true).then((res) => {
        this.roles = res.data;
      });
    },

    getSwitch(user, status) {
      console.log(status);
      this.http
        .post("users/update-status", {
          status: status,
          user_id: user.id,
        })
        .then(() => {
          this.get(this.page);
        });
    },


  },
  created() {
    this.get(1);
    this.getRole();
    // this.getUsers();
  },
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    :title="$t('menu.menuitems.users.subItems.point_of_sale_managers')" />
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box" style="width: 250px">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchUsers"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button @click="cancelSearchMode()" v-if="SearchMode" class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"></button>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button type="button" class="btn btn-light float-end mb-4" data-bs-toggle="modal" data-bs-target="#addModal">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("users.userstable.id") }}</th>
        <th scope="col">{{ $t("users.userstable.user") }}</th>
        <th scope="col">{{ $t("users.userstable.email") }}</th>
        <th scope="col">{{ $t("users.userstable.tel") }}</th>

        <th scope="col">{{ $t("users.userstable.created") }}</th>
        <th scope="col">{{ $t("users.userstable.updated") }}</th>
        <th scope="col">{{ $t("users.userstable.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center" v-for="(user, index) in users" :key="user">
        <td>{{ index + 1 }}</td>
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.tel }}</td>

        <td>{{ user.created.split("T")[0] }}</td>
        <td>{{ user.updated.split("T")[0] }}</td>
        <td>
          <button @click="getSwitch(user, 1)" v-if="user.status == 0" class="btn btn-primary btn-sm me-2">
            {{ $t("popups.enable") }}
          </button>
          <button type="button" @click="getSwitch(user, 0)" v-if="user.status == 1"
            class="btn btn-secondary btn-sm me-2">
            {{ $t("popups.disable") }}
          </button>
          <routerLink :to="{ name: 'posOfSaleManagerProfile', params: { id: user.id } }"
            class="btn btn-secondary btn-sm me-2">

            <span class="bx bx-user float-end fa-2x" style="color: #2a3042 !important"></span>
          </routerLink>
          <button type="button" class="btn btn-primary me-2 btn-sm" data-bs-target="#editModal" data-bs-toggle="modal"
            @click="userInfo(user)">
            {{ $t("popups.edit") }}
          </button>
          <button type="button" class="btn btn-primary me-2 btn-sm" data-bs-target="#editPassModal"
            data-bs-toggle="modal" @click="userInfo(user)">
            {{ $t("popups.resetpass") }}
          </button>
          <!-- <a class="btn btn-danger btn-sm me-2" :class="{}" href="javascript:void(0)" role="button"
            @click="deleteUser(user)">{{ $t("popups.delete") }}</a> -->
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!--Start Add Modal-->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addUsers()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.adduser") }}
            </h5>
            <button type="button" class="btn-close-add" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <label for="usename">{{ $t("popups.username") }}</label>
            <input v-model="data.name" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.username')" />

            <label for="user">{{ $t("popups.email") }}</label>
            <input v-model="data.email" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.email')" />
            <label for="user">{{ $t("popups.tel") }}</label>
            <input v-model="data.tel" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.tel') " />

            <label for="pass">{{ $t("popups.password") }}</label>
            <input v-model="data.pass" id="pass" required class="form-control" type="password"
              :placeholder="$t('popups.password')" />


          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!-- Edit Modal -->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editUser()">
          <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edituser") }}
            </h5>
            <button type="button" class="btn-close-edit" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0"></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <label for="usename">{{ $t("popups.username") }}</label>
            <input v-model="user_info.name" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.username')" />

            <label for="user">{{ $t("popups.email") }}</label>
            <input v-model="user_info.email" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.email')" />
            <label for="user">{{ $t("popups.tel") }}</label>
            <input v-model="user_info.tel" id="user" required class="form-control" type="text"
              :placeholder="$t('popups.tel')" />


            <!-- </div> -->
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--end Edit Modal-->
  <!--Edit Pass Modsl-->
  <div class="modal fade" id="editPassModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editPass()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.resetpass") }}
            </h5>
            <button type="button" class="btn-close-editpass" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <label for="pass" class="form-label">{{
              $t("popups.resetpass")
              }}</label>
            <input v-model="new_pass" required type="password" class="form-control" id="pass"
              :placeholder="$t('popups.resetpass')" />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Edit pass Modal-->
  <!--   Apps  Pagination     -->
  <ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
        }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == tot_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
